@import '../../../styles/mixins';
@import '../../common/typography/typography-variables';
@import '../../../styles/_variables.scss';

.location-map {
  $self: &;

  overflow: hidden;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  box-sizing: border-box;
  
  &_clickable:hover,
  &:focus {
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  &_border_none {
    border: none;
    border-radius: 0;
  }

  &__image {
    display: block;
    width: 100%;

    &_height-auto {
      height: auto;
    }
  }

  &__mark {
    @include position(absolute, $left: 50%, $bottom: 44%);
    @include translate($x: -50%, $y: 0);
  }
}
