@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../../view/components/common/typography/typography-variables';

.brand-list-menu {
  position: relative;
  padding: 20px 0 24px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__brands {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-self: stretch;
    gap: 12px 16px;
  }

  &__select-line {
    @include position(absolute, $left: 0, $bottom: -2px);
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: $red1;
    z-index: 1;
  }

  &__info {
    display: none;
  }
}

@include mobile() {
  .brand-list-menu {
    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__info {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }

    &__product-count {
      width: 100%;
      display: flex;
      padding: 11px 16px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 6px;
      background: $yellow2;
      @include inter_15_22;
      @include font-weight-medium;

      &:hover {
        color: $black1;
      }
    }

    &__phone {
      width: 100%;
      display: flex;
      gap: 4px;
      padding: 11px 16px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 6px;
      background: $blue8;
      @include inter_15_22;
      @include font-weight-medium;

      &:hover {
        color: $black1;
      }
    }

    .model-list {
      display: none;
    }
  }
}

@include desktop() {
  .brand-list-menu {
    padding: 40px 0;

    &__brands {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 16px 28px;
    }
  }
}

@media (min-width: 1440px) {
  .brand-list-menu {
    &__brands {
      gap: 16px 32px;
    }
  }
}
