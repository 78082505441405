@import '../../../styles/_variables.scss';
@import '../../../styles/mixins';
@import '../../common/typography/typography-variables';

.main-page-reviews {
  padding: 20px;
  background-color: $white;

  &__title {
    @include inter_24_32;
    @include font-weight-medium;
    margin-bottom: 8px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__more-button {
    background-color: $blue8;
    @include inter_15_22;
    @include font-weight-medium;
  }
}

@include desktop() {
  .main-page-reviews {
    padding: 40px 48px;

    &__title {
      @include inter_30_40;
      @include font-weight-medium;
    }

    &__mobile {
      display: none;
    }
  }
}
