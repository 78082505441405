@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/breakpoints';
@import '../../../components/common/typography/typography-variables';

.main-page-header {
  position: relative;
  background-color: $black2;

  &__title {
    @include inter_30_40;
    color: $colorTextWhite;
    padding: 40px 0 20px 0;
  }

  &__main-image {
    display: none;
  }
}

@include desktop() {
  .main-page-header {
    height: 304px;

    &__title {
      @include inter_60_72;
      color: $colorTextWhite;
      width: 100%;
      max-width: 800px;
      padding: 120px 0 40px;
    }

    &__main-image {
      display: block;
      position: absolute;
      bottom: 0;
      right: 60px;
      height: 100%;
      object-fit: cover;
      transition: all 0.2s ease;
    }
  }
}
