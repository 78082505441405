@import '../../../styles/mixins';
@import '../../common/typography/typography-variables';
@import '../../../styles/_variables.scss';

.placemark-locality {
  @include position(absolute, $left: 50%, $bottom: 45%);
  @include translate($x: -50%, $y: 0);
  background: $white;
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 135px;
  filter: 
    drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.10)) 
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));

  &__wrapper {
    position: relative;
  }

  &__name {
    @include font-weight-semi-bold;
    @include uppercase;
    @include add-letter-spacing;
    color: $black1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__triangle {
    @include position(absolute, $left: 50%, $bottom: -16px);
    @include translate($x: -50%, $y: 0);
  }
}
