@import '../../common/typography/typography-variables';
@import '../../../styles/mixins';

.main-page-delivery {
  display: flex;
  padding: 33px 0 20px 0;
  align-items: flex-start;
  gap: 24px;
  flex-wrap: nowrap;
  flex-direction: row;

  &__title {
    @include inter_30_40;
    @include font-weight-medium;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 31px;
    flex-shrink: 0;
  }

  &__content-info {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 24px;
  }

  &__info-item {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  &__info-icon {
    width: 60px;
    height: 72px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    &-title {
      white-space: nowrap;
    }

    &_item {
      &_pickup {
        max-width: 170px;
        white-space: nowrap;
      }

      &_courier {
        max-width: 134px;
        white-space: nowrap;
      }

      &_russianPost {
        max-width: 150px;
        white-space: nowrap;
      }
    }
  }

  &__info-description {
    color: $black3;
    white-space: pre-wrap;
  }

  &__location-container {
    overflow: hidden;
    justify-content: center;
    display: flex;
    flex-basis: auto;
    flex-shrink: 2;
    border-radius: 6px;
  }

  &__map {
    height: 160px;
    flex-shrink: 0;

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

}

@include mobile() {
  .main-page-delivery {
    flex-direction: column;

    &__title {
      @include inter_24_32;
      @include font-weight-medium;
    }

    &__content {
      gap: 19px;
    }

    &__location-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      gap: 8px;
    }

    &__map {
      width: 100%;
    }

    &__location {
      @include inter_15_22;
      @include font-weight-medium;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0 6px;
      width: 100%;
      min-width: 240px;
      padding: 11px 16px;
      border: none;
      border-radius: 6px;
      background: $blue8;

      &_desktop {
        display: none;
      }
    }
  }
}

@include desktop() {
  .main-page-delivery {
    padding: 61px 0 48px 0;
    justify-content: space-between;
    gap: 80px;

    &__content-info {
      flex-wrap: nowrap;
      gap: 60px;
    }

    &__header {
      display: flex;
      gap: 20px;
    }

    &__map {
      min-width: 266px;
      max-width: 380px;
    }

    &__location {
      &_mobile {
        display: none;
      }
    }
  }
}
