@import "../../../../styles/mixins";

.reviews-carousel {
  $self: &;
  display: flex;
  flex-direction: row;
  gap: 48px;
  position: relative;

  &__nav-button {
    position: absolute;
    height: 100%;
    width: 20px;
    padding: 0;

    &_prev {
      display: none;
      left: -28px;

      &:after {
        content: '';
        width: 80px;
        height: 100%;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, $white 100%);
        @include position(absolute, $left: 20px);
      }
    }

    &_next {
      right: -28px;
      display: none;

      &:before {
        content: '';
        width: 80px;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, $white 100%);
        @include position(absolute, $right: 20px);
      }
    }
  }

  &:hover {
    #{$self}__nav-button_prev {
      display: flex;
    }

    #{$self}__nav-button_next {
      display: flex;
    }
  }

  &__icon {
    position: absolute;
    left: 0;
  }
}

@media (max-width: 600px) {
  .reviews-carousel {
    display: none;
  }
}
