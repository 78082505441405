@import '../../../common/typography/typography-variables';
@import '../../../../styles/_mixins';

.review {
  &__title {
    display: flex;
    gap: 8px;
    @include inter_15_22;
    @include font-weight-semi-bold;
    margin-bottom: 8px;
  }

  &__person-name {
    @include inter_15_22;
    @include font-weight-semi-bold;
  }

  &__stars {
    display: flex;
  }

  &__text {
    margin-bottom: 8px;
    height: auto;
    max-height: 88px;
    @include clamp(4);
  }

  &__source {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__source-name {
    color: $blue3;
  }
}

@include desktop() {
  .review {
    flex: 33.33%;
    flex-grow: 0;

    &__text {
      height: 66px;
      @include clamp(3);
    }
  }
}
