@import '../../../common/typography/typography-variables';
@import '../../../../styles/mixins';

.sources {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
  padding: 7px 0 5px 0;

  &__title {
    @include inter_20_28;
    @include font-weight-medium;
  }

  &__item {
    display: flex;
    padding: 3px 10px 3px 4px;
    align-items: center;
    gap: 6px;
    border-radius: 20px;
    background: $blue8;
  }

  &__grade {
    @include inter_15_22;
    @include font-weight-semi-bold;
    color: $blue3;
  }
}

@include desktop() {
  .sources {
    margin-bottom: 32px;
  }
}
