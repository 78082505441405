@import '../../../../styles/variables';

.placemarks-delivery-companies {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    position: relative;
    width: 180px;
    height: 90px;
  }

  &__item {
    position: absolute;

    &_id {
      //Boxberry
      &_2 {
        left: 3px;
        top: 22px;
        z-index: 0;
      }

      //СДЭК
      &_6 {
        left: 19px;
        top: 34px;
        z-index: 1;
      }

      //DPD
      &_3 {
        left: 98px;
        top: 47px;
        z-index: 1;
      }

      //ПЭК
      &_4 {
        left: 110px;
        top: 31px;
        z-index: 0;
      }

      //Почта России
      &_11 {
        left: 50px;
        top: 8px;
        z-index: 1
      }

      //Деловые линии
      &_7 {
        left: 57px;
        top: 60px;
        z-index: 1;
      }
    }
  }

  &__placemark {
    position: relative;
    width: 27px;
    height: 38px;
  }

  &__icon {
    top: 0;
    left: 0;
    position: absolute;
    width: 28px;
    height: 40px;
  }

  &__company {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 24px;
    height: 24px;
    background-color: $white;
    border-radius: 12px;
    z-index: 1;
  }
}
