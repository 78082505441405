@import '../../../../styles/mixins';
@import '../../../../../view/components/common/typography/typography-variables';

.model-list {
  $self: &;
  position: absolute;
  width: 100%;
  display: flex;
  top: 110px;
  left: 0;
  transition: height .5s;
  background-color: $white;
  height: 0;
  padding: 0;
  margin: 0;
  z-index: 2;

  &_visible {
    height: 160px;
    padding: 60px 60px 48px 60px;
    margin: 0 -60px 0 -60px;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    background: $white;
    overflow-x: scroll;
    @include hide-scroll;
  }

  &__model-container {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    &_visible {
      display: flex;
    }

    &:hover {
      #{$self}__model-name {
        color: $blue1;
      }
    }
  }

  &__model {
    width: 212px;
    height: 120px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
  }

  &__black-cover-image {
    @include black-cover();
  }

  &__model-name {
    @include inter_20_28;
    @include font-weight-medium;
    color: $blue2;
  }

  &__left-button {
    display: none;
    @include position(absolute, $left: 8px, $top: 102px);
    z-index: 1;

    #{$self}_visible & {
      display: block;
    }
  }

  &__right-button {
    display: none;
    @include position(absolute, $right: 8px, $top: 102px);
    z-index: 1;

    #{$self}_visible & {
      display: block;
    }
  }
}
