@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../common/typography/typography-variables';

.main-page-information {
  padding: 20px;
  background-color: $white;

  &__title {
    @include inter_24_32;
    @include font-weight-medium;
    margin-bottom: 18px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 320px;
  }

  &__item {
    display: flex;
    gap: 20px;
  }

  &__icon {
    margin-left: 2px;
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    flex: 75%;
  }

  &__item-title {
    @include font-weight-medium;
    margin-bottom: 4px;
  }

  &__item-description {
    color: $black3;
  }
}

@include mobile() {
  .main-page-information {
    &__title {
      max-width: 435px;
    }
  }
}

@include desktop() {
  .main-page-information {
    padding: 40px 48px;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    &__title {
      @include inter_30_40;
      @include font-weight-medium;
      white-space: pre-wrap;
      margin-bottom: 0;
      width: 334px;
    }

    &__content {
      flex-direction: row;
      gap: 58px;
      width: 699px;
      padding-top: 3px;
    }

    &__item {
      flex-direction: row;
      flex: 1;

      &:first-child {
        flex: 43%;
      }

      &:nth-child(2) {
        flex: 47%;
      }
    }

    &__item-content {
      flex: 1;
    }
  }
}

@media (min-width: 1440px) {
  .main-page-information {
    &__title {
      width: 456px;
    }
  }
}